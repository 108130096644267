.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-layout-content {
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container{
  max-width: 1600px;
  margin: 0 auto;
}
.container.zh {
  /* padding: 20px 100px; */
}
.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: #A4C451 !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 2px rgba(151, 230, 33, 0.1) !important;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  background-color: #A4C451 !important;
}

/* tips */
.textExplain{
  text-align: right;
  color: #999;
  font-size: 14px,
}

.textExplain .ant-btn.ant-btn-icon-only .anticon {
  font-size: 20px;
}

.textExplain .ant-btn-link {
  color: #065BAE !important;
}

.textExplain .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #065BAE !important;
}

.ant-notification_tips {
  font-size: 14px;
  color: #FFF;
  margin-bottom: 12px;
  padding: 8px;
}

.ant-notification_tips .ant-notification_tips-title {
  color: #FFF;
  list-style-type: disc !important;
}


